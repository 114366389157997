<template>
  <div v-if="content"
       class="antialiased font-work"
  >
    <AgeGate />

    <TemplatesPrivacySingle v-if="page && page.collection === 'privacy'"
                            :content="data.data.value"
                            :language="language"
    />

    <TemplatesPrivacySingleCustom v-if="page && page.collection === 'privacy_custom'"
                                  :content="data.data.value"
                                  :language="language"
    />

    <TemplatesHomepage v-if="content.template === 'homepage'"
                       :content="content.content"
    />

    <TemplatesSupport v-if="content.template === 'support'"
                      :content="content.content"
    />

    <TemplatesStory v-if="content.template === 'story_characters'"
                    :content="content.content"
    />

    <TemplatesPrivacy v-if="content.template === 'privacy'"
                      :content="content.content"
    />

    <TemplatesPage v-if="content.template === 'page'"
                   :content="content"
    />

    <TemplatesVideos v-if="content.template === 'videos'"
                     :content="content.content"
    />

    <TemplatesGames v-if="content.template === 'games'"
                    :content="content.content"
    />

    <TemplatesGame v-if="content.template === 'game'"
                   :content="content.content"
                   :coming="content.comingSoonTag"
    />

    <TemplatesCharacter v-if="content.template === 'character'"
                        :content="content.content"
    />

    <TemplatesFamilyGuidance v-if="content.template === 'family_guidance'"
                             :content="content.content"
    />

    <TemplatesGameWall v-if="content.template === 'game_wall'"
                       :content="content.content"
    />
  </div>
</template>

<script setup lang="ts">
import { createError, useAsyncData, queryContent, useState, useHead, useRuntimeConfig } from '#imports';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import routesList from '../content/routes.json';

const menuCharactersShow = useState('menuCharactersShow');
const menuCharactersOpen = useState('menuCharactersOpen');
const route = useRoute();
const { baseUrl } = useRuntimeConfig().public;

type PageType = {
  url: string;
  id: string;
  collection: string;
  comingSoonTag: boolean;
};

let uri = route && route.path ? route.path : '/';

// if length more than 1, strip ending slash
if (uri.length > 1 && uri.endsWith('/')) {
  uri = uri.slice(0, -1);
}

// Privacy pages are handled differently, the first part of the url is the slug of the page and
// the second part is the language code/tag

// Default entry id is home
let entry_id = 'home';
let data: any;

const page = routesList.find(item => item.url === uri) as PageType;

// for privacy pages with customs-privacy slug prefix
const routeParamsSlugIndex = computed(() => (page.collection === 'privacy' ? 0 : 1));
const routeParamsLanguageIndex = computed(() => (page.collection === 'privacy' ? 1 : 2));

if (!page) {
  // add exception for sw /manifest.webmanifest / priniting console errors in dev
  if(route.path ==='/manifest.webmanifest' || route.path ==='/sw.js') {
    console.log('error at: ', route.path);
  } else {
    throw createError({
      statusCode: 404,
      statusMessage: 'Page Not Found',
      fatal: true
    });
  }
} else {
  entry_id = page.id;

  if ((page && page.collection === 'privacy') || page.collection === 'privacy_custom') {
    data = await useAsyncData(uri, () =>
      queryContent('privacy')
        .where({
          slug: `/${route.params.slug[routeParamsSlugIndex.value]}/`,
          ...(route.params.slug[routeParamsLanguageIndex.value] && { lang: route.params.slug[routeParamsLanguageIndex.value] })
        })
        .findOne()
    );
  } else {
    data = await useAsyncData(uri, () => queryContent('pages', entry_id).findOne());
  }
}

onMounted(() => {
  menuCharactersShow.value = content.value.template === 'character';

  if (content.value.template !== 'character') {
    menuCharactersOpen.value = false;
  }
});

const language = computed(() =>
  route && route.params && route.params.slug && route.params.slug.length >= 1 ? route.params.slug[routeParamsLanguageIndex.value] : ''
);

const content = computed(() => {
  if (data && data.data && data.data.value) {
    return data.data.value;
  }

  return false;
});

function formatCanonical(path: string) {

  if (path.startsWith('/customs-privacy')) {
    path = path.slice('/customs-privacy'.length); // Remove the /customs-privacy
  }

  return path.endsWith('/') ?
    path.slice(0, -1) :
    path;
}

useHead({
  title: data.data.value?.meta?.title ?? '',
  link: [
    {
      rel: 'canonical',
      href: baseUrl + formatCanonical(route.path)
    }
  ],
  meta: [
    { hid: 'description', name: 'description', content: data.data.value?.meta?.description ?? '' },
    { hid: 'og:title', property: 'og:title', content: data.data.value?.social?.title ?? '' },
    // { hid: 'og:url', property: 'og:url', content: this.pageUrl },
    { hid: 'og:description', property: 'og:description', content: data.data.value?.social?.description ?? '' },
    { hid: 'og:image', property: 'og:image', content: data.data.value?.social?.image ?? 'https://cdn-ttf.o7web.com/assets/social-images/og_1200x630.png' },
    { hid: 'twitter:title', name: 'twitter:title', content: data.data.value?.social?.title ?? '' },
    // { hid: 'twitter:url', name: 'twitter:url', content: this.pageUrl },
    { hid: 'twitter:description', name: 'twitter:description', content: data.data.value?.meta?.description ?? '' },
    { hid: 'twitter:image', name: 'twitter:image', content: data.data.value?.social?.image ?? 'https://cdn-ttf.o7web.com/assets/social-images/og_1200x630.png' }
  ]
});

</script>
